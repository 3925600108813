// copied with basically no revisions from https://www.npmjs.com/package/react-microsoft-clarity

function _clarity() {
  var params = Array.prototype.slice.call(arguments)
  if (!window.clarity) {
    throw new Error("Clarity is not initialized")
  }

  window.clarity.apply(undefined, params)
}

function init(id) {
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments)
      }
    t = l.createElement(r)
    t.async = 1
    t.src = "https://www.clarity.ms/tag/" + i
    y = l.getElementsByTagName(r)[0]
    y.parentNode.insertBefore(t, y)
  })(window, document, "clarity", "script", id)
}

function hasStarted() {
  return typeof window.clarity === "function"
}

function identify(userId, properties) {
  _clarity("identify", userId, properties)
}

function consent() {
  _clarity("consent")
}

function setTag(key, value) {
  _clarity("set", key, value)
}

function upgrade(reason) {
  _clarity("upgrade", reason)
}

export const clarity = {
  init,
  hasStarted,
  identify,
  consent,
  setTag,
  upgrade,
}