import React from 'react'
import { FaPooStorm } from 'react-icons/fa'
import { Link } from './link'
import './error-view.scss'

type ErrorViewProps = {
  children?: React.ReactNode
}

const ErrorView: React.FC<ErrorViewProps> = ({ children }: ErrorViewProps) => {
  return <div className="error-view">
    <div className="error-cont">
      <div><FaPooStorm size={200} /></div>
      <div style={{ fontSize: 40 }} className="mb-5">
        Well this is worse than a thunderstorm on inspection day.
      </div>
      <div className="fs-4">
        Our apologies - something went wrong on our end. We've
        notified the support team and will investigate ASAP.
        In the meantime, please reach out if you need immediate assistance.
      </div>
      <div className="buttons">
        <button
          className="btn btn-secondary"
          onClick={() => window.location.reload()}
        >Reload Page</button>
        <button
          className="btn btn-primary"
          onClick={() => window.open("https://www.oneethos.com/contact-us", "_blank")}
        >Contact Support</button>
      </div>
      {children ? children : null}
    </div>
  </div>
}

export default ErrorView