import React from 'react'
import ErrorView from './error-view'
import * as Sentry from "@sentry/react"

type ErrorBoundaryProps = {
  fallback?: React.ReactNode
}

/**
 * Function components don't support componentDidCatch, so we need to use a class component
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <ErrorView />
    }

    return this.props.children
  }
}